import { PlantingIcon } from "./icons/planting";
import { PlantingEventForm as PlantingEventFormView } from "./event-planting-form";
import { EventPlantingInfo } from "./event-planting-info";
import { messages } from "./i18n-messages";

import { connect } from "react-redux";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import {
    actions as picklistActions,
    selectors as picklistSelectors,
    picklistNames,
} from "~/core/picklist";
import { actions as unitActions, selectors as unitSelectors, unitNames } from "~/core/units";
import * as selectors from "../../event-module/components/event-info/selectors";
import * as actions from "../../event-module/components/event-info/actions";

const mapStateToProps = (state) => {
    const rowOrientation = picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_ROW_ORIENTATION)
    );
    return {
        brandOrgData: selectors.getBrandOrgState(state),
        cropData: selectors.getCropDropdownState(state),
        cropPurpose: picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROP_PURPOSE)
        ),
        dryPriceUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRICE_UNIT_DRY)
        ),
        dryProductUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRODUCT_RATE_DRY)
        ),
        liquidPriceUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRICE_UNIT_LIQUID)
        ),
        liquidProducteUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PRODUCT_RATE_LIQUID)
        ),
        packageUnit: unitSelectors.getUnitPicklistFromCode(
            state,
            unitNames.getUnitCode(unitNames.UNIT_PACKAGE)
        ),
        plantSpacing: picklistSelectors.getPicklistFromCode(state, "plantSpacing"),
        plantingCropAge: picklistSelectors.getPicklistFromCode(state, "plantingCropAge"),
        plantingDepth: picklistSelectors.getPicklistFromCode(state, "plantingDepth"),
        plantingPattern: picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_PLANTING_PATTERN)
        ),
        productData: selectors.getProductData(state),
        rowOrientation: rowOrientation.sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        ),
        rowSpacing: picklistSelectors.getPicklistFromCode(state, "rowSpacing"),
        seedingRate: picklistSelectors.getPicklistFromCode(state, "seedingRate"),
        transplantRate: picklistSelectors.getPicklistFromCode(state, "transplantRate"),
        transplantType: picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_TRANSPLANT_TYPE)
        ),
        varietyHybridData: selectors.getVarietyHybridDropdownState(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchDropdownData: (dropdowns) =>
        dispatch(
            fetchDropdownData({
                ...dropdowns,
                action: actions.fetchedDropdownData,
            })
        ),
    fetchPicklistData: (payload) => dispatch(picklistActions.fetchPicklistData(payload)),
    fetchUnitData: (payload) => dispatch(unitActions.fetchUnitData(payload)),
    fetchSystemAttributePicklist: (payload) =>
        dispatch(actions.fetchSystemAttributePicklist(payload)),
});

export const formLabelIcon = PlantingIcon;
export const formLabelMessage = messages.plantingFormLabelText;
export const SummarySection = EventPlantingInfo;

export const PlantingEventForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(PlantingEventFormView);
