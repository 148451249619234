import { action } from "typesafe-actions";

export const BATCH_UPDATE_PRODUCT_BLEND = "rec-info/BATCH_UPDATE_PRODUCT_BLEND";
export const CLOSE_EVENT_INFO = "event-info/CLOSE_EVENT_INFO";
export const COPY_EVENT_INFO = "event-info/COPY_EVENT_INFO";
export const FETCH_EVENT_FILTER_SUMMARY = "recs-events/FETCH_EVENT_FILTER_SUMMARY";
export const FETCH_TILLAGE_FORM_PICKLISTS = "event-info/FETCH_TILLAGE_FORM_PICKLISTS";
export const SAVE_EVENT_INFO = "event-info/SAVE_EVENT_INFO";
export const SET_EC_DATA_LOADING = "event-info/SET_EC_DATA_LOADING";
export const SET_EVENT_DETAILS_LOADING = "event-info/SET_EVENT_DETAILS_LOADING";
export const SET_EVENT_FILTER_DATA = "recs-events/SET_EVENT_FILTER_DATA";
export const SET_EVENT_SUMMARY = "event-info/SET_EVENT_SUMMARY";
export const SHOW_EC_DATA_INFO = "event-info/SHOW_EC_DATA_INFO";
export const SHOW_EVENT_INFO = "event-info/SHOW_EVENT_INFO";
export const SET_SHOW_FILTER_POINTS = "event-info/SET_SHOW_FILTER_POINTS";
export const UPDATE_EVENT_INFO_PICKLISTS = "event-info/UPDATE_EVENT_INFO_PICKLISTS";
export const FETCHED_DROPDOWN_DATA = "event-info/FETCHED_DROPDOWN_DATA";
export const FETCH_SYSTEM_ATTRIBUTE_PICKLIST = "event-info/FETCH_SYSTEM_ATTRIBUTE_PICKLIST";
export const PREVIEW_MERGE_EVENTS = "event-info/PREVIEW_MERGE_EVENTS";
export const MERGE_EVENTS = "event-info/MERGE_EVENTS";

export const ADD_IN_FLIGHT_COLLAPSING_EVENTS = "event-info/ADD_IN_FLIGHT_COLLAPSING_EVENTS";
export const REMOVE_IN_FLIGHT_COLLAPSING_EVENTS = "event-info/REMOVE_IN_FLIGHT_COLLAPSING_EVENTS";

export const batchUpdateProductBlend = (
    productMix: Record<string, any>,
    previousMixGA: Record<string, any>
) => action(BATCH_UPDATE_PRODUCT_BLEND, { productMix, previousMixGA });

export const closeEventInfo = () => action(CLOSE_EVENT_INFO, null);

export const copyEventInfo = (eventSummary: Record<string, any>) =>
    action(COPY_EVENT_INFO, { eventSummary });

export const fetchTillageFormPicklists = () => action(FETCH_TILLAGE_FORM_PICKLISTS, null);

export const fetchEventFilterSummary = (agEventGeneralGuid: string, filters: Record<string, any>) =>
    action(FETCH_EVENT_FILTER_SUMMARY, { agEventGeneralGuid, filters });

export const saveEventInfo = () => action(SAVE_EVENT_INFO, null);

export const setECDataLoading = (ecDataLoading: boolean) =>
    action(SET_EC_DATA_LOADING, { ecDataLoading });

export const setEventDetailsLoading = (isLoading: boolean) =>
    action(SET_EVENT_DETAILS_LOADING, { isLoading });

export const setEventFilterData = (eventFilterData: Record<string, any>) =>
    action(SET_EVENT_FILTER_DATA, { eventFilterData });

export const setEventSummary = (eventSummary: Record<string, any>) =>
    action(SET_EVENT_SUMMARY, { eventSummary });

export const showEcDataInfo = (surfaceGuid: string) => action(SHOW_EC_DATA_INFO, { surfaceGuid });

export const showEventInfo = (eventSummary: Record<string, any>) =>
    action(SHOW_EVENT_INFO, { eventSummary });

export const setShowFilterPoints = (showFilterPoints: Record<string, any>, fieldGuid: string) =>
    action(SET_SHOW_FILTER_POINTS, { showFilterPoints, fieldGuid });

export const updateEventInfoPicklists = (eventInfoPicklists: Record<string, any>) =>
    action(UPDATE_EVENT_INFO_PICKLISTS, { eventInfoPicklists });

export const fetchedDropdownData = (payload: Record<string, any>) =>
    action(FETCHED_DROPDOWN_DATA, payload);

export const fetchSystemAttributePicklist = (payload: Record<string, any>) =>
    action(FETCH_SYSTEM_ATTRIBUTE_PICKLIST, payload);

export const previewMergeEvents = (primaryEventSummary: Record<string, any>) =>
    action(PREVIEW_MERGE_EVENTS, { primaryEventSummary });

export const mergeEvents = (mergeableEvents: Record<string, any>) =>
    action(MERGE_EVENTS, { mergeableEvents });

export const addInFlightCollapsingEvents = (eventSummaries: Record<string, any>) =>
    action(ADD_IN_FLIGHT_COLLAPSING_EVENTS, { eventSummaries });

export const removeInFlightCollapsingEvents = (agEventGeneralGuids: string[]) =>
    action(REMOVE_IN_FLIGHT_COLLAPSING_EVENTS, { agEventGeneralGuids });
