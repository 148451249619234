import { createReducer } from "redux-act";
import * as actions from "./actions";

export const VARIETY_HYBRID_DATA_KEY = "VARIETY_HYBRID_DATA";

export const brandVarietyHybridListReducer = createReducer(
    {
        [actions.fetchVarietyHybridListSuccess as any]: (state, payload) => ({
            ...state,
            brandVarietyHybridList: {
                ...state.brandVarietyHybridList,
                [payload.guid]: payload.response,
            },
        }),
        [actions.fetchVarietyHybridListFailed as any]: (state) => ({
            ...state,
            brandVarietyHybridList: {},
        }),
    },
    {
        brandVarietyHybridList: {},
    }
);
