import { takeEvery, put, select, PutEffect, SelectEffect, ForkEffect } from "redux-saga/effects";
import * as actions from "./actions";
import { CropAPI } from "@ai360/core";
import { actions as notificationActions } from "~/notifications";
// selectors
import { getTheUserGuid } from "~/login/selectors";
import { IActionData } from "../../interfaces";

export function* fetchVarietyHybridList(
    action: IActionData
): Generator<SelectEffect | Promise<any> | PutEffect<any>, void, unknown> {
    const { payload } = action;
    if (payload) {
        const UserGuid = yield select(getTheUserGuid);
        try {
            const response = yield CropAPI.requestVarietyHybrid({
                UserGuid,
                Model: payload,
            });
            if (response) {
                yield put(
                    actions.fetchVarietyHybridListSuccess({
                        guid: payload.brandOrganization,
                        response,
                    })
                );
            } else {
                yield put(actions.fetchVarietyHybridListFailed("No Data"));
            }
        } catch (error) {
            yield put(actions.fetchVarietyHybridListFailed(error));
            yield put(notificationActions.apiCallError(error, action));
        }
    } else {
        yield put(actions.fetchVarietyHybridListFailed("No Data"));
    }
}

export const varietyHybridListSaga = function* (): Generator<ForkEffect, void, unknown> {
    yield takeEvery(actions.fetchVarietyHybridList, fetchVarietyHybridList);
};
