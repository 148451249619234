import { ReportAPI, ReportsModels } from "@ai360/core";

export enum reportStatus {
    GENERATED = 3,
    GENERIC_GP_SERVICE_ERROR = 9999,
    IN_PROGRESS = 2,
    RAD_PDF_ERROR = 590,
    STATUS_ERROR = 4,
}

export const DEFAULT_SELECTED_BASEMAP = "reportImagery";
export const DEFAULT_AGVANCE_CROP_COLOR = "crop";
export const DEFAULT_LEGEND_TYPE = "crop";
export const DEFAULT_LABEL_TYPE = "none";

// Reports listed here are handled in the Reports API
// (NOT in Legacy API)
export const NEW_REPORTS: string[] = [
    ReportAPI.ReportNames.AGVANCE_SKY_FIELD_REFERENCE_LIST,
    ReportAPI.ReportNames.AGVANCE_SKY_HARVEST_SUMMARY,
    ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_SUMMARY,
    ReportAPI.ReportNames.AQUALENZ,
    ReportAPI.ReportNames.YIELD_MATRIX_REPORT,
];

export const SAMPLE_BAG_LABEL_TYPES = {
    Avery6572: "Avery 6572",
    Avery8160: "Avery 8160",
    Thermal: "Thermal",
};

export const DEFAULT_AGE_OF_SAMPLE = "3";
export const DEFAULT_NUMBER_OF_SEASONS = "3";
export const DEFAULT_DISPLAY_TYPE = "coverage";
export const DEFAULT_ORIENTATION = ReportsModels.PageOrientation.LANDSCAPE;
export const DEFAULT_CROP_MAP_ORIENTATION = ReportsModels.CropMapOrientation.DYNAMIC;
export const DEFAULT_CROP_MAP_LEGEND_TYPE = "crop";
export const DEFAULT_CROP_MAP_LABEL_TYPE = "none";
export const DEFAULT_CROP_ZONE = false;
export const DEFAULT_CROP_COLOR = "crop";
export const DEFAULT_PLANTING_ATTRIBUTE = "seeding";
export const DEFAULT_SOIL_BAG_LABEL_ATTRIBUTE = SAMPLE_BAG_LABEL_TYPES.Avery8160;
export const DEFAULT_SOIL_TYPE_ATTRIBUTE = "Soil Type Name";
export const DEFAULT_YIELD_ATTRIBUTE = "dry";
export const DEFAULT_APPLICATION_ATTRIBUTE = "rate";
export const DEFAULT_SKY_APP_ATTRIBUTE = "productMix";
