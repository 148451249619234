import * as actions from "./actions";
import { IAction } from "./models";

export const sampleTissueInitialState = {
    showProductivityRating: false,
    cropGrowthStageList: [],
    cropGrowthStageGuid: null,
    plantPartGuid: null,
};

export const sampleTissueReducer = (
    state = sampleTissueInitialState,
    action: IAction
): Record<string, unknown> => {
    switch (action.type) {
        case actions.SET_SHOW_PRODUCTIVITY_RATING: {
            const { showProductivityRating } = action.payload;
            return Object.freeze({ ...state, showProductivityRating });
        }
        case actions.SET_CROP_GROWTH_STAGE_LIST: {
            const { cropGrowthStageList } = action.payload;
            return Object.freeze({ ...state, cropGrowthStageList });
        }
        case actions.SET_CROP_GROWTH_STAGE_GUID: {
            const { cropGrowthStageGuid } = action.payload;
            return Object.freeze({ ...state, cropGrowthStageGuid });
        }
        case actions.SET_CROP_PURPOSE_GUID: {
            const { cropPurposeGuid } = action.payload;
            return Object.freeze({ ...state, cropPurposeGuid });
        }
        case actions.SET_PLANT_PART_GUID: {
            const { plantPartGuid } = action.payload;
            return Object.freeze({ ...state, plantPartGuid });
        }
        default:
            return state;
    }
};
