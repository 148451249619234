import React, { useEffect, useRef, useState } from "react";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { service } from "./service";
import * as model from "./model";
import { messages } from "../../i18n-messages";
import { errorCodeMessages } from "~/i18n-error-messages";
import { injectIntl, intlShape } from "react-intl";
import { withCrud, withMasked } from "~/hocs";
import { actions } from "./controller";
import { keywords } from "../../keywords";
import { Email } from "~/admin/setup";
import { constants } from "../../data";
import { connect } from "react-redux";
// Components
import { DataTable, NoLink } from "~/core";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { ReportViewer } from "../report-viewer/report-viewer";
import { getSelectedFieldGuids } from "../../../customer-data/selectors";
import { ErrorDetailsDialog } from "~/action-panel/components/common/error-details-dialog/error-details-dialog";
import {
    IFilterQuery,
    ISortOptions,
    IIndex,
    IDataTableFooterOptions,
    IRecord,
} from "~/core/components/tables/interfaces";

export interface IEmailData {
    FileName?: string;
    UserGuid?: string;
    selectedItems?: Record<string, any>[];
    emailAlias?: string;
    isError?: boolean;
}

export interface IReportData {
    fileName?: string;
    isPrint?: boolean;
    loggedInUserGuid?: string;
    userGuid?: string;
    reportUserGuid?: string;
    selectedItems?: Record<string, any>[];
    emailAlias?: string;
}

export interface ISendEmail {
    [data: string]: Record<string, any>;
}
export interface IReportTable_Props {
    actions?: Record<string, any>;
    autoSearchList?: Record<string, any>;
    autoSearchUrl?: string;
    createReportHubProgress: boolean;
    currentThemeName?: string;
    deleteSelectedReports?: (records: Record<string, any>[]) => void;
    deleteSelectedReportsAlt?: (records: Record<string, any>[]) => void;
    hideMergeOption?: boolean;
    sendEmail?: (emailToSend: ISendEmail) => void;
    fetchRecords?: () => void;
    fetchRecordsAlt?: () => void;
    getAutoSearchListAlt?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    initialRequestOptions?: Record<string, any>;
    intl: intlShape;
    needs?: (any?: void[]) => string | void;
    onAllReportCreated?: () => void;
    onFilterChangeAlt?: (attr: string, value: string, filterQuery: IFilterQuery) => void;
    onPaginatorChangeAlt?: (opts: Partial<IIndex>) => void;
    onSelectAllAlt?: (opts: Partial<IIndex>, clearSelected?: boolean) => void;
    onSortOptionAlt?: (opts?: ISortOptions) => void;
    setIsExpanded?: (isExp: boolean) => void;
    downloadReports?: (models: Record<string, any>) => void;
    printReports?: (data: Record<string, any>) => void;
    mergeReports?: (model: Record<string, any>) => void;
    clearReportHubData?: () => void;
    records?: Record<string, any>[];
    reportName?: string;
    reportStatus?: Record<string, any>[];
    requestIds?: string;
    selectAllUrl?: string;
    selectedFields?: Immutable.Set<string>;
    selectedItems: string[];
    selectedItemList?: string[];
    totalCount?: number;
    url?: string;
    userGuid?: string;
}

const ReportTable = (props: IReportTable_Props): JSX.Element => {
    const {
        autoSearchList,
        autoSearchUrl,
        createReportHubProgress,
        currentThemeName,
        deleteSelectedReports,
        deleteSelectedReportsAlt,
        downloadReports,
        fetchRecords,
        fetchRecordsAlt,
        hideMergeOption,
        intl,
        mergeReports,
        onAllReportCreated,
        printReports,
        records,
        requestIds,
        reportName,
        reportStatus,
        selectAllUrl,
        selectedFields,
        selectedItems,
        selectedItemList,
        sendEmail,
        setIsExpanded,
        url,
        userGuid,
    } = props;
    const { formatMessage } = intl;

    //state variables

    const [currentThemeNameState, setCurrentThemeNameState] = useState<string>("");
    const [deleteRequestId, setDeleteRequestId] = useState<string>(null);
    const [reportErrorDetailsToShow, setReportErrorDetailsToShow] = useState<string>(null);
    const [reportStatusState, setReportStatusState] = useState<Record<string, any>[]>([]);
    const [reportViewerData, setReportViewerData] = useState<IReportData>(null);
    const [selectedFieldsState, setSelectedFieldsState] = useState<string[]>([]);
    const [selectedItemsState, setSelectedItems] = useState<any[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
    const [showReportView, setShowReportView] = useState<boolean>(false);
    const [tableFooterOptions, setTableFooterOptions] = useState<IDataTableFooterOptions[]>([]);
    const [reportsTobeDeleted, setReportsTobeDeleted] = useState<IIndex>(null);
    const [printReportsRequestId, setPrintReportsRequestId] = useState<string>(null);
    const [reportsTobeEmailed, setReportsTobeEmailed] = useState<IEmailData[]>([]);
    const [sendEmailData, setSendEmailData] = useState<IEmailData[]>([{}]);

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const previousProps: any = usePrevious({ createReportHubProgress });

    useEffect(() => {
        if (url) {
            service.urls.URL = url;
        }
        if (autoSearchUrl) {
            service.urls.AUTO_SEARCH_URL = autoSearchUrl;
        }
        if (selectAllUrl) {
            service.urls.SELECT_ALL = selectAllUrl;
        }

        setTableFooterOptions(getTableFooterOptions());
    }, []);

    useEffect(() => {
        setTableFooterOptions(getTableFooterOptions());
    }, [hideMergeOption, records]);

    useEffect(() => {
        if (!previousProps?.createReportHubProgress && createReportHubProgress) {
            processFieldOrProgressChange();
        }
        if (reportStatus.length > 0) {
            const someReportsInProgress = reportStatus.some(
                (report) => report.status === constants.reportStatus.IN_PROGRESS
            );
            if (createReportHubProgress && !someReportsInProgress) {
                onAllReportCreated();
            }
        }
        setReportStatusState(reportStatus);
    }, [createReportHubProgress, reportStatus]);

    useEffect(() => {
        processFieldOrProgressChange();
    }, [selectedFields]);

    useEffect(() => {
        setCurrentThemeNameState(currentThemeName);
    }, [currentThemeName]);

    useEffect(() => {
        if (
            selectedItemList != null &&
            JSON.stringify(selectedItemList) !== JSON.stringify(selectedItemsState)
        ) {
            setSelectedItems(selectedItemList);
        }
    }, [selectedItemList]);

    useEffect(() => {
        setSelectedItems(selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        if (requestIds && deleteRequestId) {
            if (requestIds[deleteRequestId] === "SUCCESS") {
                if (fetchRecordsAlt != null) {
                    fetchRecordsAlt();
                } else {
                    fetchRecords();
                }
                setDeleteRequestId(null);
            } else if (requestIds[deleteRequestId] === "FAILURE") {
                setDeleteRequestId(null);
            }
            setShowDeleteModal(false);
            setReportsTobeDeleted(null);
        }

        if (requestIds && printReportsRequestId) {
            if (requestIds[printReportsRequestId] === "SUCCESS") {
                const reportData = {
                    fileName: keywords.outputMerge,
                    loggedInUserGuid: userGuid,
                };

                _openReportViewer(reportData, true);
                setPrintReportsRequestId(null);
            } else if (requestIds[printReportsRequestId] === "FAILED") {
                setPrintReportsRequestId(null);
            }
        }
    }, [requestIds]);

    const processFieldOrProgressChange = () => {
        service.defaultRequestFilters[model.PROPS_FIELD_GUID_LIST] = Array.from(selectedFields);

        createdReports = [];
        failedReports = [];

        if (fetchRecordsAlt != null) {
            fetchRecordsAlt();
        } else {
            fetchRecords();
        }
        setSelectedItems([]);
        setSelectedFieldsState(Array.from(selectedFields));
    };

    const emailSelected = (data: IIndex): any => {
        const reportsForEmail = [...reportsTobeEmailed];
        reportsForEmail.splice(0, reportsForEmail.length);
        reportsForEmail.push(
            ...records
                .filter(
                    (record) => (data.selectedItems as IRecord[]).indexOf(record.reportGuid) > -1
                )
                .map((record) => {
                    return {
                        FileName: record.reportLink ? record.reportLink : record.fileName,
                        UserGuid: record.reportUserGuid,
                    };
                })
        );
        setReportsTobeEmailed(reportsForEmail);
        setShowEmailModal(true);
    };

    const downloadSelected = (data: IIndex): void => {
        const reportList = records
            ?.filter((record) => (data.selectedItems as IRecord[]).indexOf(record.reportGuid) > -1)
            .map((record) => ({
                FileName: record.reportLink ? `${record.reportLink}.pdf` : `${record.fileName}.pdf`,
                UserGuid: record.reportUserGuid,
            }));

        downloadReports({
            model: reportList || [],
        });
    };

    const printSelected = (data: IIndex): void => {
        const reportList = records
            ?.filter((record) => (data.selectedItems as IRecord[]).indexOf(record.reportGuid) > -1)
            .map((record) => ({
                FileName: record.reportLink ? `${record.reportLink}.pdf` : `${record.fileName}.pdf`,
                UserGuid: record.reportUserGuid,
            }));
        setPrintReportsRequestId(props.needs([printReports(reportList || [])]) as string);
    };

    const mergeSelected = (data: IIndex): void => {
        const reportList = records
            ?.filter((record) => (data.selectedItems as IRecord[]).indexOf(record.reportGuid) > -1)
            .map((record) => ({
                FileName: record.reportLink ? `${record.reportLink}.pdf` : `${record.fileName}.pdf`,
                ReportGuid: record.reportGuid,
                UserGuid: record.reportUserGuid,
            }));

        mergeReports({
            model: reportList || [],
        });
    };

    const _printReportStatus = (data, attr) => {
        if (attr === model.PROPS_REPORT_STATUS) {
            const currentReport = reportStatusState.filter((report) => {
                return report.reportGuid === data.reportGuid;
            });
            if (currentReport.length > 0) {
                const status = currentReport.pop().status;
                if (status === constants.reportStatus.IN_PROGRESS) {
                    return (
                        <div
                            className="report-progress-text"
                            title={formatMessage(messages.inProgress)}
                        >
                            {formatMessage(messages.inProgress)}
                        </div>
                    );
                } else if (status === constants.reportStatus.GENERATED) {
                    createdReports.push(data.reportGuid);
                    return _renderViewReportLabel(data);
                } else {
                    failedReports.push(data.reportGuid);
                    return _getErrorInReportStatus(status, data, attr);
                }
            } else {
                if (
                    data[attr] === keywords.completed ||
                    createdReports.indexOf(data.reportGuid) > -1
                ) {
                    return _renderViewReportLabel(data);
                } else if (
                    data[attr] === keywords.inProgress &&
                    failedReports.indexOf(data.reportGuid) === -1
                ) {
                    return (
                        <span
                            className="report-progress-text"
                            title={formatMessage(messages.inProgress)}
                        >
                            {formatMessage(messages.inProgress)}
                        </span>
                    );
                } else {
                    return _renderErrorInReportLabel(data[attr], data.reportGuid);
                }
            }
        }
        return data[attr];
    };

    const _getTitleValue = (data, attr) => {
        if (attr === "reportDate") {
            const createdBy =
                data["createdBy"] !== null && data["createdBy"] !== ""
                    ? data["createdBy"] + " - "
                    : "";
            return createdBy + data["reportDate"];
        }
        return data[attr];
    };

    let createdReports = [];
    let failedReports = [];
    const sendEmailDataItems = [...sendEmailData];

    const getTableFooterOptions = () => {
        const options = [
            {
                label: formatMessage(messages.deleteSelected),
                action: deleteSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: false,
            },
            {
                label: formatMessage(messages.emailSelected),
                action: emailSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: true,
            },
            {
                label: formatMessage(messages.printSelected),
                action: printSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: true,
            },
            {
                label: formatMessage(messages.mergeSelected),
                action: mergeSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: true,
            },
            {
                label: formatMessage(messages.downloadSelected),
                action: downloadSelected,
                disableIfNotSelected: true,
                disableIfNotSuccessful: true,
            },
        ];
        if (hideMergeOption) {
            return options.filter((tfo) => tfo.label !== formatMessage(messages.mergeSelected));
        }

        return options;
    };
    service.printLabelValue = _printReportStatus;
    service.getTitleValue = _getTitleValue;

    const _getErrorInReportStatus = (status, data, attr): JSX.Element => {
        const { formatMessage } = intl;
        switch (status) {
            case constants.reportStatus.GENERIC_GP_SERVICE_ERROR:
                return _renderErrorInReportLabel(
                    formatMessage(messages.genericGpServiceError),
                    data.reportGuid
                );
            case constants.reportStatus.STATUS_ERROR:
                return _renderErrorInReportLabel(
                    formatMessage(messages.statusError),
                    data.reportGuid
                );
            case constants.reportStatus.RAD_PDF_ERROR:
                return _renderErrorInReportLabel(
                    formatMessage(messages.radPdfError),
                    data.reportGuid
                );
            default:
                return _renderErrorInReportLabel(data[attr], data.reportGuid);
        }
    };

    const _reportDeleteSelected = (data) => {
        const reportData = records
            ?.filter((record) => data.selectedItems.indexOf(record.reportGuid) > -1)
            .map((record) => ({
                FileName: `${record.reportLink}.pdf`,
                UserGuid: record.reportUserGuid,
                ReportGuid: record.reportGuid,
            }));
        setSelectedItems(
            selectedItemsState.filter((item) => {
                //reduce the list by those deleted reports then save that value in state
                return data.selectedItems.some(
                    (delItems) => delItems.reportGuid === item.reportGuid
                );
            })
        );

        if (deleteSelectedReportsAlt != null) {
            deleteSelectedReportsAlt(
                records?.filter((record) => data.selectedItems.indexOf(record.reportGuid) > -1)
            );
        } else {
            setDeleteRequestId(props.needs([deleteSelectedReports(reportData)]) as string);
            deleteSelectedReports(reportData);
        }
        setShowDeleteModal(false);
    };

    const deleteSelected = (data: IIndex): void => {
        setReportsTobeDeleted(data);
        setShowDeleteModal(true);
        return null;
    };

    const _getLabelString = () => {
        let returnValue = { count: records?.length || 1 };
        const count = { count: records?.length || 1 };
        if (reportName === keywords.summary) {
            returnValue = formatMessage(messages.summaryReportsHeader, count);
        } else {
            returnValue = formatMessage(messages.reportTableHeader, count);
        }
        return `3. ${returnValue}:`;
    };

    const isValidEmail = (email: string) => {
        const simpleEmailRegex = /@.+/;
        return simpleEmailRegex.test(email);
    };

    const getInvalidEmails = () => {
        const invalidEmails = sendEmailData.filter((e) => !isValidEmail(e.emailAlias));
        return invalidEmails.map((e) => e.emailAlias);
    };

    const _sendEmail = () => {
        const invalidEmails = getInvalidEmails();
        if (invalidEmails?.length > 0) {
            const adjustedEmailData = sendEmailData.map((e) => {
                return {
                    ...e,
                    isError: invalidEmails.includes(e.emailAlias),
                };
            });

            setSendEmailData(adjustedEmailData);
            return;
        }
        sendEmail({
            [model.PROPS_EMAIL_ADDRESS_LIST]: sendEmailData.map((item) => item.emailAlias),
            [model.PROPS_REPORT_OBJECT_LIST]: reportsTobeEmailed,
        });
        setShowEmailModal(false);
    };

    const _openReportViewer = (reportData, isPrint = false) => {
        setShowReportView(true);
        setCurrentThemeNameState(currentThemeName);
        setReportViewerData({
            fileName: reportData.reportLink ? reportData.reportLink : reportData.fileName,
            loggedInUserGuid: userGuid,
            reportUserGuid: reportData.reportUserGuid ? reportData.reportUserGuid : "",
            isPrint,
        });
        setIsExpanded(false);
    };

    const _renderViewReportLabel = (data) => {
        return (
            <NoLink
                onClick={() => {
                    _openReportViewer(data);
                }}
                label={formatMessage(messages.viewReport)}
                title={formatMessage(messages.viewReport)}
            />
        );
    };

    const _renderErrorInReportLabel = (value, reportGuid): JSX.Element => {
        return (
            <NoLink
                onClick={() => setReportErrorDetailsToShow(reportGuid)}
                className="report-error-text"
                label={value}
                title={value}
            />
        );
    };

    const closeReportViewer = (): void => {
        setShowReportView(false);
        setIsExpanded(true);
        setReportViewerData({});
    };

    const isFooterButtonDisabled = (
        disableIfNotSelected: boolean,
        selectedItemsData: Record<string, any>[],
        disableIfNotSuccessful = false
    ): boolean => {
        let areAllSuccessful = true;
        selectedItemsData.forEach((guid) => {
            const currReport = records?.find((record) => record.reportGuid === guid);
            if (currReport) {
                if (currReport.reportStatus === keywords.inProgress) {
                    const isInReportStatus = reportStatusState.some(
                        (r) =>
                            r.reportGuid === guid && r.status === constants.reportStatus.GENERATED
                    );
                    areAllSuccessful = areAllSuccessful && isInReportStatus;
                } else if (currReport.reportStatus !== keywords.completed) {
                    areAllSuccessful = false;
                }
            }
        });
        return (
            (disableIfNotSelected && selectedItemsData.length === 0) ||
            (disableIfNotSuccessful && !areAllSuccessful)
        );
    };

    const onAddOrDeleteEmail = (newEmailData: IEmailData[]): void => {
        setSendEmailData(newEmailData);
    };

    const onEmailChange = (key, newEmailData: IEmailData[]): void => {
        setSendEmailData(newEmailData);
    };

    service.defaultRequestFilters[model.PROPS_FIELD_GUID_LIST] = selectedFieldsState;
    service.isFooterButtonDisabled = isFooterButtonDisabled;
    return (
        <div className="report-grid-cont">
            <div className="options-header report-table-header">{_getLabelString()}</div>
            <DataTable
                autoSearchList={autoSearchList}
                classNames="reports-table-cont"
                isCheckbox
                isSelectAll={false}
                service={service}
                messages={messages}
                {...props}
                deleteSelected={deleteSelected}
                footerOptions={tableFooterOptions}
                selectedItems={selectedItemsState}
            />
            <DialogBox
                title={formatMessage(messages.confirmTitle)}
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={showDeleteModal}
                onAction={() => _reportDeleteSelected(reportsTobeDeleted)}
                onClose={() => setShowDeleteModal(false)}
            >
                <div>{formatMessage(errorCodeMessages.confirmDeleteMsg)}</div>
            </DialogBox>
            <DialogBox
                actionDisabled={sendEmailDataItems.some(
                    (e) => e.emailAlias === "" || e.emailAlias === undefined
                )}
                draggable={true}
                title={formatMessage(messages.sendReportTo)}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="send"
                isOpen={showEmailModal}
                onAction={() => _sendEmail()}
                onClose={() => setShowEmailModal(false)}
            >
                <Email
                    noHeader
                    emailList={sendEmailDataItems}
                    emailListAlias={"email"}
                    emailAlias={"emailAlias"}
                    formKey={"emailList"}
                    addEditPanel={{ mode: "EDIT" }}
                    emailAddText={messages.additionalEmail}
                    onAdd={onAddOrDeleteEmail}
                    onChildComponentChange={onEmailChange}
                    onDelete={onAddOrDeleteEmail}
                    required={true}
                />
            </DialogBox>
            {!showReportView ? null : (
                <SlidingPanel
                    noHeader
                    component={ReportViewer}
                    navigateTo={{
                        parentNameCode: "105",
                        childNameCode: "227",
                    }}
                    data={reportViewerData}
                    currentThemeName={currentThemeNameState}
                    closeReportViewer={closeReportViewer}
                    formatMessage={formatMessage}
                />
            )}
            {!reportErrorDetailsToShow ? null : (
                <ErrorDetailsDialog
                    onClose={() => setReportErrorDetailsToShow(null)}
                    correlationId={reportErrorDetailsToShow}
                    logType="report"
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    selectedFields: getSelectedFieldGuids(state),
});

export const ReportTableView = injectIntl(
    withMasked(
        withCrud(
            connect<Partial<IReportTable_Props>>(mapStateToProps, null)(ReportTable),
            service,
            actions
        )
    )
);
