import { getModuleState as getEventModuleState } from "../../selectors";
import { getModuleState as getLayerListModuleState } from "../../../layer-module/selectors";

import { LAYER_LIST_STATE_KEY } from "../../../layer-module/components/layer-list/selectors";

export const EVENT_INFO_STATE_KEY = "EVENT_INFO_STATE";
export const ACCORDION_KEY = "ACCORDION";
export const DROPDOWNS_KEY = "DROPDOWNS";

export const getModuleState = (state) => getEventModuleState(state)[EVENT_INFO_STATE_KEY];

export const getAccordionState = (state) => getModuleState(state)[ACCORDION_KEY];

export const getLayerAccordionState = (state) =>
    getLayerListModuleState(state)[LAYER_LIST_STATE_KEY][ACCORDION_KEY];

export const getCropDropdownState = (state) => getModuleState(state)[DROPDOWNS_KEY].cropData;

export const getECDataLoading = (state) => getModuleState(state).ecDataLoading;

export const getPriceDropdownState = (state) => getModuleState(state)[DROPDOWNS_KEY].priceUnit;

export const getBrandOrgState = (state) => getModuleState(state)[DROPDOWNS_KEY].brandOrgData;

export const getVarietyHybridDropdownState = (state) =>
    getModuleState(state)[DROPDOWNS_KEY].varietyHybridData;

export const getProductData = (state) => getModuleState(state)[DROPDOWNS_KEY].productData;

export const getInFlightCollapsingEvents = (state) =>
    getModuleState(state).inFlightCollapsingEvents;

export const getEventSummary = (state) => getModuleState(state).eventSummary;
